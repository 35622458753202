import { FRONT_URL } from '../const/const';

/**
 * Function to open new tab to upload files
 * @param {Object} param0 that receives apiKey and identtifier
 */
const redirect = ({
  apiKey,
  identifier,
}: {
  apiKey: string;
  identifier: string;
}) => {
  const json = {
    apiKey,
    identifier,
    popup: false,
    mobile: false,
    onlyCapture: false,
  };
  const jsonToBase64 = btoa(JSON.stringify(json));

  window.open(`${FRONT_URL}?state=${jsonToBase64}`);
};

export default redirect;
