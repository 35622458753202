import { FRONT_URL } from '../const/const';

/**
 * Function to display a popup to upload files
 * @param {Object} param0 that receives apiKey and identtifier
 */
const popup = ({
  apiKey,
  identifier,
}: {
  apiKey: string;
  identifier: string;
}) => {
  const json = {
    apiKey,
    identifier,
    popup: true,
    mobile: false,
    onlyCapture: false,
  };
  const jsonToBase64 = btoa(JSON.stringify(json));

  window.open(
    `${FRONT_URL}?state=${jsonToBase64}`,
    'popUpWindow',
    'height=600,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
  );
};

export default popup;
